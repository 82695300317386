import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Header } from './Components/Header/Header';
import { Footer } from './Components/Footer/Footer';
import { Homepage } from './Pages/Homepage/Homepage';


function App() {
  return (
    <>
    <Header/>
    <Homepage/>








    <Footer/>
    </>
  );
}

export default App;
