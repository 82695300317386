import React from 'react'
import product from '../../Assets/Images/t1.webp';
import product2 from '../../Assets/Images/t2.webp';
import product3 from '../../Assets/Images/t3.webp';
import product4 from '../../Assets/Images/t4.webp';
import product5 from '../../Assets/Images/t5.webp';
import product6 from '../../Assets/Images/t6.webp';
import './Topselling.css'

export const Topselling = () => {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="headignDiv">
                                <h2>TOP SELLING PRODUCTS</h2>
                                <p>Commodo sociosqu venenatis cras dolor sagittis integer luctus sem primis eget maecenas.</p>
                            </div>
                        </div>


                        <div className="col-md-4">
                            <div className="productMain">
                                <div className="productImg">
                                    <img src={product} alt="" />

                                    <div className="productbtn">
                                        <button>Buy Now</button>
                                    </div>
                                </div>

                                <div className="priceDetails">
                                    {/* <p>Voluptatem quaerat nesciunt</p> */}
                                    {/* <a href="https://api.whatsapp.com/send?phone=+919910448000&text=Hello%20The%20Luxury%20Affairs." class="float" target="_blank">
                                       <button className='btn btn-primary bg-dark color-white'>Contact For Details </button>
                                    </a> */}
                                    <a href="https://api.whatsapp.com/send?phone=+919910448000&text=Hello%20The%20Luxury%20Affairs." class="float" target="_blank">
                                       <button className='btn btn-primary bg-dark color-white'>Contact For Details </button>
                                    </a>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="productMain">
                                <div className="productImg">
                                    <img src={product2} alt="" />
                                    <div className="productbtn">
                                        <button>Buy Now</button>
                                    </div>
                                </div>

                                <div className="priceDetails">
                                    {/* <p>Voluptatem quaerat nesciunt</p> */}
                                    <a href="https://api.whatsapp.com/send?phone=+919910448000&text=Hello%20The%20Luxury%20Affairs." class="float" target="_blank">
                                       <button className='btn btn-primary bg-dark color-white'>Contact For Details </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="productMain">
                                <div className="productImg">
                                    <img src={product3} alt="" />
                                    <div className="productbtn">
                                        <button>Buy Now</button>
                                    </div>
                                </div>

                                <div className="priceDetails">
                                    {/* <p>Voluptatem quaerat nesciunt</p> */}
                                    <a href="https://api.whatsapp.com/send?phone=+919910448000&text=Hello%20The%20Luxury%20Affairs." class="float" target="_blank">
                                       <button className='btn btn-primary bg-dark color-white'>Contact For Details </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="productMain">
                                <div className="productImg">
                                    <img src={product4} alt="" />
                                    <div className="productbtn">
                                        <button>Buy Now</button>
                                    </div>
                                </div>

                                <div className="priceDetails">
                                    {/* <p>Voluptatem quaerat nesciunt</p> */}
                                    <a href="https://api.whatsapp.com/send?phone=+919910448000&text=Hello%20The%20Luxury%20Affairs." class="float" target="_blank">
                                       <button className='btn btn-primary bg-dark color-white'>Contact For Details </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="productMain">
                                <div className="productImg">
                                    <img src={product5} alt="" />
                                    <div className="productbtn">
                                        <button>Buy Now</button>
                                    </div>
                                </div>

                                <div className="priceDetails">
                                    {/* <p>Voluptatem quaerat nesciunt</p> */}
                                    <a href="https://api.whatsapp.com/send?phone=+919910448000&text=Hello%20The%20Luxury%20Affairs." class="float" target="_blank">
                                       <button className='btn btn-primary bg-dark color-white'>Contact For Details </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="productMain">
                                <div className="productImg">
                                    <img src={product6} alt="" />
                                    <div className="productbtn">
                                        <button>Buy Now</button>
                                    </div>
                                </div>

                                <div className="priceDetails">
                                    {/* <p>Voluptatem quaerat nesciunt</p> */}
                                    <a href="https://api.whatsapp.com/send?phone=+919910448000&text=Hello%20The%20Luxury%20Affairs." class="float" target="_blank">
                                       <button className='btn btn-primary bg-dark color-white'>Contact For Details </button>
                                    </a>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="centerBtn text-center">
                                <button className='custmBTn'>SHOW ALL PRODUCTS</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
