import React from 'react'
import './Header.css'
import logoimg from '../../Assets/Images/logo.png'
export const Header = () => {
    return (
        <>
            <section className='navHeader'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="logoDiv">
                                <img src={logoimg} alt="logo" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="container">
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <div className="NavDiv">
                                <ul>
                                    <li><a href="#">About</a></li>
                                    <li><a href="#">Men</a></li>
                                    <li><a href="#">Women</a></li>
                                    <li><a href="#">Kids</a></li>
                                    <li><a href="#">Accessories</a></li>
                                    <li><a href="#">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="NavDiv">
                                <ul>
                                    <li><a href="#" title='Login/Sign UP'><i class="fa-solid fa-arrow-right-to-bracket"></i></a></li>
                                    <li><a href="#" title='Cart'><i class="fa-solid fa-cart-shopping"></i></a></li>
                                    <li><a href="#" title='Log Out'><i class="fa-solid fa-power-off"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
