import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import './Banner.css'
import HomePageBanner from '../../Assets/Images/banner2.webp';
import HomePageBanner2 from '../../Assets/Images/banner1.webp';
export const Banner = () => {
    return (
        <>
                <div className="container-fluid" style={{ padding: '0px', margin: '0px',overflow: 'hidden' }}>
                    <div className="row">
                        <div className="col-md-12" >
                            <OwlCarousel className='owl-theme' loop margin={0} nav dots={false} autoplay={true} responsive={{

                                0: {
                                    items: 1
                                },
                                600: {
                                    items: 1
                                },
                                1366: {
                                    items: 1
                                }
                            }}>

                                <div class='item'>
                                    <div className="HomePageBanner">
                                        <img src={HomePageBanner} alt="image" />
                                    </div>
                                </div>
                                <div class='item'>
                                    <div className="HomePageBanner">
                                        <img src={HomePageBanner2} alt="image" />
                                    </div>
                                </div>
                                <div class='item'>
                                    <div className="HomePageBanner">
                                        <img src={HomePageBanner} alt="image" />
                                    </div>
                                </div>
                                <div class='item'>
                                    <div className="HomePageBanner">
                                        <img src={HomePageBanner2} alt="image" />
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
           


            {/* <section className="bannerDivMain">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="bannerText">
                        <h1>One stop destination for <br/> Bridal &   Occassion wear</h1>
                        <p>
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ut reprehenderit, officiis dicta <br />dignissimos libero alias sint culpa ea.
                        </p>
                        <a href="#"><button className='custmBTn' style={{backgroundColor: '#434343', color: '#fff'}}>Get Started <i className="fa-solid fa-arrow-right"></i></button></a>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
        </>
    )
}
