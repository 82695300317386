import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Banner } from '../../Components/Banner/Banner'
import aboutImg from '../../Assets/Images/aboutImg.webp'
import catmen from '../../Assets/Images/mens.webp'
import catwomen from '../../Assets/Images/womenscat.webp'
import cataccseories from '../../Assets/Images/jwellaryscat.webp'
import './Homepage.css'
import { Topselling } from '../../Components/Topselling/Topselling'
import showpr1 from '../../Assets/Images/purse.jpg'
import showpr2 from '../../Assets/Images/jwellery.jpg'
import showpr3 from '../../Assets/Images/jwellery2.jpg'
import bannerShow from '../../Assets/Images/bottomMen.webp'
import bannerShow2 from '../../Assets/Images/bottomWomen.webp'
import PhotoshootVideo from '../../Assets/Videos/photoshootVdos.mp4'
// import { InstagramFeed } from '../../Components/Instagram/Instagram';

export const Homepage = () => {
    return (
        <div>
            <Banner />

            <Topselling />

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="headignDiv">
                                <h2>Shop by Catagories</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 catagry">
                            <OwlCarousel className='owl-theme' loop margin={10} nav dots={false} responsive={{

                                0: {
                                    items: 1
                                },
                                600: {
                                    items: 2
                                },
                                1366: {
                                    items: 5
                                }
                            }}>

                                <div className='item'>
                                    <div className="catagoryHome">
                                        <div className="shopbycatagory">
                                            <img src={catmen} alt="image" />
                                        </div>

                                    </div>
                                    <div className="shocatagoryTxt">
                                        <p>Women's Wear</p>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="catagoryHome">
                                        <div className="shopbycatagory">
                                            <img src={catwomen} alt="image" />
                                        </div>

                                    </div>
                                    <div className="shocatagoryTxt">
                                        <p>Men's Wear </p>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="catagoryHome">
                                        <div className="shopbycatagory">
                                            <img src={cataccseories} alt="image" />
                                        </div>

                                    </div>
                                    <div className="shocatagoryTxt">
                                        <p>Accessories</p>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="catagoryHome">
                                        <div className="shopbycatagory">
                                            <img src={catmen} alt="image" />
                                        </div>

                                    </div>
                                    <div className="shocatagoryTxt">
                                        <p>Women's Wear</p>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="catagoryHome">
                                        <div className="shopbycatagory">
                                            <img src={catwomen} alt="image" />
                                        </div>

                                    </div>
                                    <div className="shocatagoryTxt">
                                        <p>Men's Wear </p>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="catagoryHome">
                                        <div className="shopbycatagory">
                                            <img src={cataccseories} alt="image" />
                                        </div>

                                    </div>
                                    <div className="shocatagoryTxt">
                                        <p>Accessories</p>
                                    </div>
                                </div>
                               
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>




            <section className='festivemomnts marginDiv'>
                <div className="container-fluid m-0 p-0">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="video-container">
                                <video autoplay="autoplay" loop="loop" muted id="background-video">
                                    <source src={PhotoshootVideo} type="video/mp4" />
                                    <source src={PhotoshootVideo} type="video/webm" />
                                </video>
                                <div className="festivemomntstext ">
                                    <p>Elegance for</p>
                                    <h2>-Festive Moments-</h2>
                                    <p>Elevate every festive occasion with timeless elegance. Experience sophistication and charm, <br /> crafted to make your celebrations truly memorable.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="headignDiv">
                                <h2>New Arrivals</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <OwlCarousel className='owl-theme' loop margin={10} nav dots={false} responsive={{
                                0: {
                                    items: 1
                                },
                                600: {
                                    items: 2
                                },
                                1366: {
                                    items: 4
                                }
                            }}>

                                <div className='item'>
                                    <div className="NewArrival">
                                        <div
                                            className="image-container"
                                            style={{
                                                '--default-image': `url(${require('../../Assets/Images/NewAr1b.webp')})`,
                                                '--hover-image': `url(${require('../../Assets/Images/NewAr1f.webp')})`
                                            }}
                                        >
                                            <div className="image"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="NewArrival">
                                        <div
                                            className="image-container"
                                            style={{
                                                '--default-image': `url(${require('../../Assets/Images/NewAr2f.webp')})`,
                                                '--hover-image': `url(${require('../../Assets/Images/NewAr2b.webp')})`
                                            }}
                                        >
                                            <div className="image"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="NewArrival">
                                        <div
                                            className="image-container"
                                            style={{
                                                '--default-image': `url(${require('../../Assets/Images/NewAr3f.webp')})`,
                                                '--hover-image': `url(${require('../../Assets/Images/NewAr3b.webp')})`
                                            }}
                                        >
                                            <div className="image"></div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div className='item'>
                                    <div className="NewArrival">
                                        <div
                                            className="image-container"
                                            style={{
                                                '--default-image': `url(${require('../../Assets/Images/NewAr2f.webp')})`,
                                                '--hover-image': `url(${require('../../Assets/Images/NewAr2b.webp')})`
                                            }}
                                        >
                                            <div className="image"></div>
                                        </div>
                                    </div>
                                </div>
                              
                              
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="headignDiv">
                                <h2>Shop by Accessories</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste quae corporis recusandae deserunt! Dolorum doloremque.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="AccessoryDiv">
                                <img src={showpr1} alt="product" />
                                <div className="accsoriesText">
                                    <h2>Accessories</h2>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                    </p>
                                    <button className='custmBTn'>Shop Now</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="AccessoryDiv">
                                <img src={showpr2} alt="product" />
                                <div className="accsoriesText">
                                    <h2>Accessories</h2>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                    </p>
                                    <button className='custmBTn'>Shop Now</button>
                                </div>
                            </div>

                            <div className="AccessoryDiv">
                                <img src={showpr3} alt="product" />
                                <div className="accsoriesText">
                                    <h2>Accessories</h2>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                    </p>
                                    <button className='custmBTn'>Shop Now</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="centerBtn text-center">
                                <button className='custmBTn'>SHOW ALL PRODUCTS</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <section>
                <div className="container-fluid">
                <div className="row">
                        <div className="col-md-12">
                            <div className="headignDiv">
                                <h2>Instagram</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste quae corporis recusandae deserunt! Dolorum doloremque.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <OwlCarousel className='owl-theme' loop margin={10} autoplay={true} dots={false} responsive={{
                                0: {
                                    items: 1
                                },
                                600: {
                                    items: 2
                                },
                                1366: {
                                    items: 6
                                }
                            }}>

                                <div className='item'>
                                    <div className="instaThumbnail">
                                        <img src={aboutImg} alt="insta" />
                                        <i class="fa-brands fa-instagram"></i>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="instaThumbnail">
                                        <img src={aboutImg} alt="insta" />
                                        <i class="fa-brands fa-instagram"></i>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="instaThumbnail">
                                        <img src={aboutImg} alt="insta" />
                                        <i class="fa-brands fa-instagram"></i>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="instaThumbnail">
                                        <img src={aboutImg} alt="insta" />
                                        <i class="fa-brands fa-instagram"></i>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="instaThumbnail">
                                        <img src={aboutImg} alt="insta" />
                                        <i class="fa-brands fa-instagram"></i>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="instaThumbnail">
                                        <img src={aboutImg} alt="insta" />
                                        <i class="fa-brands fa-instagram"></i>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className="instaThumbnail">
                                        <img src={aboutImg} alt="insta" />
                                        <i class="fa-brands fa-instagram"></i>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section> */}
{/* <section>
    <InstagramFeed/>
</section> */}
            <section id='accerys'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="AccessoryDiv">
                                <img src={bannerShow} alt="product" />
                                <div className="menwomenText">
                                    <p>FOR MEN PRODUCTS</p>
                                    <h2>Premium Lorem Ipsum</h2>
                                    <p>Choose this leather Sandler backpack from the luxury affairs</p>
                                    <button className='custmBTn'>SHOP COLLECTION</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="AccessoryDiv">
                                <img src={bannerShow2} alt="product" />
                                <div className="menwomenText">
                                    <p>FOR WOMEN PRODUCTS</p>
                                    <h2>Premium Lorem Ipsum</h2>
                                    <p>Choose this leather Sandler backpack from the luxury affairs</p>
                                    <button className='custmBTn'>SHOP COLLECTION</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <section className="marginDiv">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6" style={{ position: "relative" }}>
                            <div className="aboutImg">
                                <img src={aboutImg} alt="aboutImg.jpg" width="100%" height="auto" />
                            </div>
                            <div className="aboutImgbefore"></div>
                        </div>
                        <div className="col-md-6">
                            <div className="aboutText">
                                <span>About Our Company</span>
                                <h2 className="sec-title mb-5">One stop destination for<br /><span style={{ color: "#e8c4ae" }}>  Bridal & Occassion wear</span></h2>
                            </div>
                            <div className="aboutShorttext">Welcome to The Luxury Affairs, your one-stop destination for exquisite bridal and occasion wear. We specialize in curating the finest selection of elegant gowns, sophisticated suits, and dazzling accessories, ensuring that every special moment is adorned with timeless beauty and grace. Our collection includes not only stunning attire but also a variety of luxurious accessories such as necklaces, earrings, and bracelets to complete your look.
                                <br />
                                <br />

                                Designed to cater to the discerning tastes of our clientele, we offer bespoke pieces that blend traditional craftsmanship with contemporary style. At The Luxury Affairs, we are committed to making your celebrations unforgettable, providing you with attire and accessories that reflect your unique personality and the grandeur of the occasion.</div>



                            <div className="row mt-5">
                                <div className="col-md-4">
                                    <div className="abtBtnDiv">
                                        <button className="custmBTn">Contact Us <i className="fa-solid fa-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="abtBtnDiv">
                                        <div className="abtCall">
                                            <i className="fa-solid fa-phone"></i>
                                        </div>
                                        <div className="abtContactDetails">
                                            <span>Call for help</span>
                                            <p>
                                                91 124 3467 2345
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>















            <section className='marginDiv'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="uspDiv">
                                <i className="fa-solid fa-truck-fast"></i>
                                <h2>Free Shipping</h2>
                                <p>Once receiving your order, we will turn your products around in 3- 5 business days.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="uspDiv">
                                <i className="fa-solid fa-right-left"></i>
                                <h2>Free Returns</h2>
                                <p>We accept returns for freshly purchased products within 7 days from the payment.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="uspDiv">
                                <i className="fa-solid fa-scissors"></i>
                                <h2>Custom Sizing</h2>
                                <p>Order your dress with free custom sizing.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="uspDiv">
                                <i className="fa-solid fa-ranking-star"></i>
                                <h2>Superb Quality</h2>
                                <p>We make commitments that the quality of our products will and always will be superb.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
